import "animate.css";
import React from "react";
import "tailwindcss/tailwind.css";
import "./App.css";
import { Services } from "./components/services/Services";
import Header from "./utils/header/Header";
import { Slide } from "./utils/slide/Slide";

const App = () => {
  return (
    <div className="container">
      <Header />
      <Slide />
      <Services />
    </div>
  );
};

export default App;
