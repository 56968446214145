import React from "react";
import "./services.css";

export const Services = () => {
  return (
    <div className="services">
      <div className="slogan-service">
        <div className="slogan-service-box animate__animated animate__backInDown">
          <div className="slogan-text">Brows By Nancy</div>
        </div>
      </div>
      <div className="figura-horizontal-right animate__animated slogan-service"></div>
    </div>
  );
};
