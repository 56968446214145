// @ts-ignore
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React from "react";
import "./slide.css";

export const Slide = () => {
  return (
    <Splide
      className="slide animate__animated animate__fadeInUp"
      options={{
        rewind: true,
        gap: 0,
        type: "fade",
        autoWidth: true,
        autoHeight: true,
        speed: 800,
        interval: 5000,
        heightRatio: 0.43,
        easing: "ease",
        autoplay: true,
      }}
    >
      <SplideSlide>
        <img
          src="https://figurebrows.com/images/slide01.jpg"
          sizes="100vw"
          alt="Image 1"
        />
      </SplideSlide>
      <SplideSlide>
        <img src="https://figurebrows.com/images/slide02.jpg" alt="Image 2" />
      </SplideSlide>
      <SplideSlide>
        <img src="https://figurebrows.com/images/slide03.jpg" alt="Image 3" />
      </SplideSlide>
      <SplideSlide>
        <img src="https://figurebrows.com/images/slide04.jpg" alt="Image 3" />
      </SplideSlide>
    </Splide>
  );
};
