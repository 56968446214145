import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <div className="nav">
      <div className="left-menu">
        <ul>
          <li>
            <a>Home</a>
          </li>
          <li>
            <a>About us</a>
          </li>
          <li>
            <a>Services</a>
          </li>
        </ul>
      </div>
      <div className="logo"></div>
      <div className="right-menu">
        <ul>
          <li>
            <a>Galery</a>
          </li>
          <li>
            <a>Blog</a>
          </li>
          <li>
            <a>Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Header;
